import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Layout from '../components/Layout';
import SplitContainer from '../components/SplitContainer';
import SEO from '../components/SEO';
import styles from './testimonials.module.css';

function TestimonialsPage() {
  const data = useStaticQuery(graphql`
    query {
      chcImage: file(relativePath: { eq: "chc.jpg" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      rpImage: file(relativePath: { eq: "rp.jpg" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      usauImage: file(relativePath: { eq: "usau.jpg" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      ayuImage: file(relativePath: { eq: "ayu.jpg" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      rmkImage: file(relativePath: { eq: "rmk.jpg" }) {
        childImageSharp {
          fixed(width: 250) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `);

  return (
    <Layout>
      <SEO title="Testimonials" />
      <h1>Testimonials</h1>

      <SplitContainer
        className={styles.splitContainer}
        childStyle={{ flexBasis: 'auto' }}
      >
        {
          [
            <Img
              key="logo"
              className={styles.companyLogo}
              fixed={data.rpImage.childImageSharp.fixed}
            />,
            <p key="testimonial" className={styles.quote}>
              Race, equity, diversity and inclusion matter in our lives,
              community and the workplace. When taking steps to evaluate your
              organization’s current state and planning for the future, you
              need an experienced, skilled, and collaborative partner. Dr.
              Hannah Matthys has proven to be that partner. She took the time
              to help us formulate goals and address our concerns. Along with
              her team, she developed a plan to assess our organizational and
              personal intercultural competence and used this baseline to
              recommend cross-cultural training. Under the guidance of Dr.
              Hannah Matthys we are building our capability to shift
              perspective and adapt behavior to cultural difference and
              commonality while fostering a more inclusive environment. I
              highly recommend Dr. Matthys and her team.
              <span className={styles.author}>Guy Pasquino, Executive Director</span>
              <span className={styles.organization}>Reading Partners Colorado</span>
            </p>
          ]
        }
      </SplitContainer>

      <SplitContainer
        className={styles.splitContainer}
        childStyle={{ flexBasis: 'auto' }}
      >
        {
          [
            <Img
              key="logo"
              className={styles.companyLogo}
              fixed={data.usauImage.childImageSharp.fixed}
            />,
            <p key="testimonial" className={styles.quote}>
              If there’s one thing I’ve learned from working with Dr. Hannah
              Matthys, it’s that her intention behind everything she does is
              to enact real and impactful change. Her leadership and
              direction was one of the primary factors in our organization
              making a public commitment with our partners in Aurora to
              actively advance our equity, diversity and inclusion (EDI) work
              to serve BIPOC communities there. She facilitated a focus group
              that provided concrete data on how to best serve our
              constituents, and then oversaw strategic meetings as we
              collaborated to develop a plan of action. There can be a
              tendency for organizations to fall back on traditional ways of
              doing things, but Hannah was effective in holding stakeholders
              accountable and reminding them of the actions our community
              called for. She was vital in opening our minds to new ways of
              operating that would be inclusive of more communities, and she
              will continue be a valuable partner for us going forward as we
              implement the strategies she helped us put together.
              <span className={styles.author}>Larry Melton, Manager - Communications &amp; Development; Co-Chair of EDI Team</span>
              <span className={styles.organization}>USA Ultimate</span>
            </p>
          ]
        }
      </SplitContainer>

      <SplitContainer
        className={styles.splitContainer}
        childStyle={{ flexBasis: 'auto' }}
      >
        {
          [
            <Img
              key="logo"
              className={styles.companyLogo}
              fixed={data.chcImage.childImageSharp.fixed}
            />,
            <p key="testimonial" className={styles.quote}>
              Dr. Hannah Matthys&apos; presentations clearly made a
              significant impact on the adoptive parents involved with
              Heritage Camps for Adoptive Families. Our parents enter these
              conversations with varying degrees of knowledge about race in
              the U.S. and Hannah was able to meet them where they are at, so
              to speak. It can sometimes be difficult to provide the
              foundational information for families who are at the earlier
              stages of their awareness, while also providing new learning
              opportunities for other parents, but Hannah very adeptly met a
              variety of needs. Additionally, her webinars really made space
              for families to personalize this new information, and to think
              about how it applies to their children and their family. We
              truly felt that participants left with a more nuanced
              understanding of how race intersects with the experiences of
              transracial adoption.
              <span className={styles.author}>Pam Sweetser, Executive Director</span>
              <span className={styles.organization}>Heritage Camps for Adoptive Families</span>
            </p>
          ]
        }
      </SplitContainer>

      <SplitContainer
        className={styles.splitContainer}
        childStyle={{ flexBasis: 'auto' }}
      >
        {
          [
            <Img
              key="logo"
              className={styles.companyLogo}
              fixed={data.rmkImage.childImageSharp.fixed}
            />,
            <p key="testimonial" className={styles.quote}>
              We knew we needed to do something as a company to address the
              subject of diversity and Hannah at Be Brave was our answer.
              Hannah came very well recommended and for good reason. She
              opened a thoughtful and interesting conversation with my team
              around the subject of diversity and identity that went far
              beyond the typical. Using several easy-to-employ techniques,
              Hannah had our team talking about themselves and their
              perspectives on identity in ways we had not experienced before.
              We are not only more aware of one another as individuals, we
              have been discussing ways of integrating these teachings into
              our company and team meetings.
              <span className={styles.author}>Jamba Dunn, CEO</span>
              <span className={styles.organization}>Rowdy Mermaid</span>
            </p>
          ]
        }
      </SplitContainer>

      <SplitContainer
        className={styles.splitContainer}
        childStyle={{ flexBasis: 'auto' }}
      >
        {
          [
            <Img
              key="logo"
              className={styles.companyLogo}
              fixed={data.ayuImage.childImageSharp.fixed}
            />,
            <p key="testimonial" className={styles.quote}>
              Dr. Hannah Matthys is a powerhouse! She combines vision and
              energy with excellent work habits, expertise, humor and
              compassion. Hannah has helped guide our Board through raising
              EDI work to a top priority. Specifically, as leader of
              Altitude’s Equity, Diversity and Inclusion taskforce, Hannah
              has recruited diverse community members and planned meaningful,
              long-term training and outreach in areas traditionally
              underserved in our community. She has developed a coach
              certification and piloted a course to provide potential coaches
              with cultural-sensitivity training and techniques. Another
              recent initiative arose after members of our community
              expressed concern about our relationship with the City of
              Aurora, where racist police practices had garnered national
              attention. Hannah organized a task force with Black members of
              the ultimate community as well as residents of the City of
              Aurora. She devised a format where community voices could be
              shared and heard, and the task force produced a series of
              actionable recommendations which have guided further work for
              our organization. Hannah’s ability to organize, lead and
              empower our organization and stakeholders has proved incredibly
              valuable to our community.
              <span className={styles.author}>Philip Lohre, Board President</span>
              <span className={styles.organization}>Altitude Youth Ultimate</span>
            </p>
          ]
        }
      </SplitContainer>
    </Layout>
  );
}

export default TestimonialsPage;
